var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "header",
      [
        _c("el-input", {
          attrs: { placeholder: "Search client", size: "mini" },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        }),
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.addContact } },
          [_vm._v(" New ")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "inner" }, [
      _c(
        "ul",
        _vm._l(_vm.filtered_clients, function (client) {
          return _c(
            "li",
            {
              key: client.id,
              on: { click: () => _vm.navigateToClient(client) },
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    placement: "top",
                    content: "Client is missing primary contact email",
                  },
                },
                [
                  !client.email
                    ? _c("div", { staticClass: "tag" }, [
                        _c("i", { staticClass: "el-icon-warning no-email" }),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("span", [_vm._v(" " + _vm._s(client.name) + " ")]),
              _c("svgicon", {
                staticClass: "triangle",
                attrs: { name: "triangle" },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }